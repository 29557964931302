.errorBoundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 100vh;
  background-color: white;

  img {
    max-width: 400px;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 1.7rem;
  }

  a {
    font-size: 1.2rem;
    text-decoration: underline;
    margin-top: 2rem;
  }
}
